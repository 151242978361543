import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { setInterval } from 'timers';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  AdsQueue, delay, getInitQueues, getProviderAds, getQueueConfig, IAdLoop,
} from './requests';
import { AdType, Device } from '../../types';
import useInterval from './useInterval';
import {
  cleanCurrentAd,
  cleanupExpiredAds,
  getAdLoopByType,
  getProviderAdIndexByPriority,
  getSimplifiedQueueWeights,
  getWeightedAdArray,
  increment,
  isAdProvider,
} from '../../utils/helpers';
import {
  axiosConfig, handleQueues, isProduction, queues, streetsUrl,
} from '../../config';
import {
  AdsDelay, DirectAdLogs, Log, registerDirectAdLog, registerLog, sendQueueCapReachedNotification,
} from './api';
import { Actions, StrapiComponentType, useAdLoopReducer } from './useAdLoopReducer';
import AdCounter from './AdCounter';
import AdPlayer from './AdPlayer';
import { getWeather } from '../Weather/helpers';
import PortlTransition from './Transition';
import { Article, getLocalNews } from '../News/helpers';
import { useComponentRef } from './useComponentRef';
import { socket } from '../../socket';

const AD_REQUEST_BATCH_SIZE = 10;

// const DEFAULT_LATITUDE = 43.6532;
// const DEFAULT_LONGITUDE = -79.3832;

const WEATHER_FETCH_INTERVAL = 30 * 60 * 1000;
const NEWS_FETCH_INTERVAL = 30 * 60 * 1000;

const AdContainer = styled.div`
    width: 100%;
    height: initial;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
  `;

const BrandedImage = styled.img`
    height: 101%;
    width: 101%;
  `;

const Logo = styled.img`
    padding: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 13%;
  `;

type AdLoopProps = {
  device?: Device
};

const DEFAULT_QUEUE_CAP = {
  [AdType.Hivestack]: 25,
  [AdType.Vistar]: 50,
  [AdType.Broadsign]: 50,
};

const DEFAULT_QUEUE_NOTIFICATION_PARAMETERS = {
  threshold: 70,
  cooldown: 120,
};

const DEFAULT_PROVIDER_PRIORITIES = [
  AdType.Hivestack,
  AdType.Broadsign,
  AdType.Vistar,
];

const DEFAULT_AD_REQUEST_INTERVALS = {
  [AdType.Hivestack]: 2000,
  [AdType.Vistar]: 2000,
  [AdType.Broadsign]: 2000,
};

const DEFAULT_AD_EXPIRATION_TIMES = {
  [AdType.Hivestack]: 60 * 60 * 1000,
  [AdType.Vistar]: 15 * 60 * 1000,
  [AdType.Broadsign]: 4 * 60 * 60 * 1000,
};

const AdLoop: FC<AdLoopProps> = ({
  device,
}) => {
  const [state, dispatch] = useAdLoopReducer();
  const counts = useRef<Record<string, number>>({
    // [AdType.Hivestack]: 0,
    // [AdType.Broadsign]: 0,
  });
  const pops = useRef(0);
  const adsDelay = useRef<AdsDelay>({ totalDelay: {}, count: {} });
  const log = useRef<Log>(
    {
      pops: {}, adsQueued: {}, failedPops: {}, adsRequested: {}, latestAdRequest: null, queues: {}, adsDelay: {},
    },
  );
  const latestAdRequest = useRef<number | null>(null);
  const directLog = useRef<DirectAdLogs>([]);
  const componentRef = useComponentRef();
  const history = useHistory();

  const queueCap = useRef<Record<string, number>>(DEFAULT_QUEUE_CAP);
  const queueNotificationParameters = useRef<Record<string, number>>(DEFAULT_QUEUE_NOTIFICATION_PARAMETERS);
  const providerPriorities = useRef<AdType[]>(DEFAULT_PROVIDER_PRIORITIES);
  const adRequestIntervals = useRef<Record<string, number>>(DEFAULT_AD_REQUEST_INTERVALS);
  const adExpirationTime = useRef<Record<string, number>>(DEFAULT_AD_EXPIRATION_TIMES);

  // country in URL for testing
  const country = history.location.search?.split('?')[2] || 'ca';

  useEffect(() => {
    if (!queues.current?.componentQueue || !device) {
      return;
    }

    const { componentQueue } = queues.current;

    const weather = componentQueue?.filter((comp) => comp.AssetUrlOrComponentName === 'WeatherTile')[0];
    // @ts-ignore
    let fetchWeatherInterval;
    if (weather) {
      let retryCount = 0;

      const weatherError = () => {
        // @ts-ignore
        componentRef.current.weather = { error: true };
        retryCount = 0;
      };

      const fetchWeather = async () => {
        const lat = (device as Device)?.lat;
        const lng = (device as Device)?.lng;

        if (!lat || !lng) {
          weatherError();
          return;
        }

        const response = await getWeather({
          lat,
          lng,
        });

        const MAX_RETRY_COUNT = 5;

        if (response.error && retryCount < MAX_RETRY_COUNT) {
          retryCount += 1;
          fetchWeather();
          return;
        }

        if (retryCount === MAX_RETRY_COUNT) {
          weatherError();
          return;
        }

        retryCount = 0;
        // @ts-ignore
        componentRef.current.weather = {
          ...response,
          duration: weather.PlayLength || 15,
          weight: weather.Frequency || 1,
          error: false,
        };
      };
      fetchWeather();

      fetchWeatherInterval = setInterval(fetchWeather, WEATHER_FETCH_INTERVAL);
    }

    const news = componentQueue?.filter((comp) => comp.AssetUrlOrComponentName === 'NewsTile')[0];
    // @ts-ignore
    let fetchNewsInterval;

    if (news) {
      let retryCount = 0;

      const newsError = () => {
        // @ts-ignore
        componentRef.current.news = { error: true };
        retryCount = 0;
      };

      const fetchNews = async () => {
        const response = await getLocalNews();

        const MAX_RETRY_COUNT = 5;

        if ((response.error || !response.articles || response.articles.length === 0) && retryCount < MAX_RETRY_COUNT) {
          retryCount += 1;
          fetchNews();
          return;
        }

        if (retryCount === MAX_RETRY_COUNT) {
          newsError();
          return;
        }

        const { articles } = response;

        if (!articles) {
          newsError();
          return;
        }

        const filteredArticles = articles.filter(
          (article: Article) => article.urlToImage && article.url && article.title && article.publishedAt,
        );

        if (filteredArticles.length === 0) {
          newsError();
          return;
        }

        const index = Math.floor(Math.random() * filteredArticles.length);

        let article = filteredArticles[index];

        if (article.title.includes(' - ')) {
          // remove everything after '-' from title
          article = {
            ...article,
            title: article.title.substring(0, article.title.indexOf(' - ')),
          };
        }
        componentRef.current.news = {
          article, duration: news.PlayLength || 15, weight: news.Frequency || 1, error: false,
        };
      };
      fetchNews();

      fetchNewsInterval = setInterval(fetchNews, NEWS_FETCH_INTERVAL);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // @ts-ignore
      clearInterval(fetchWeatherInterval);
      // @ts-ignore
      clearInterval(fetchNewsInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queues.current?.componentQueue, device]);

  const triggerPopFlow = async ({
    // @ts-ignore
    ad, onPopSuccess = () => {}, onPopError = () => {}, onPopFinally = () => {},
  }) => {
    try {
      await axios.get(`${streetsUrl}/pop?url=${ad.pop}`, axiosConfig);
      pops.current += 1;
      increment({ object: log.current.pops, key: ad.type });
      onPopSuccess();
    } catch (error) {
      increment({ object: log.current.failedPops, key: ad.type });
      console.error('Error Pop', error);
      onPopError();
    } finally {
      onPopFinally();
    }
  };

  const popAds = async () => {
    if (!queues.current || !queues.current.providerQueue.length) return;

    if (!queues.current.poppedAdsQueue) {
      queues.current.poppedAdsQueue = [];
    }

    const POPPED_ADS_QUEUE_THRESHOLD = 5;
    while (queues.current.providerQueue.length > 0 && queues.current.poppedAdsQueue.length < 5) {
      const adIndex = getProviderAdIndexByPriority(queues.current.providerQueue, providerPriorities.current);

      if (adIndex < 0) break;

      const ad = queues.current?.providerQueue[adIndex];

      if (queues.current?.poppedAdsQueue?.length === POPPED_ADS_QUEUE_THRESHOLD) break;

      // logging programmatic ads
      if (ad?.pop && isAdProvider(ad?.type)) {
        // compute delay
        const adDelay = new Date().getTime() - ad.createdAt;
        increment({ object: adsDelay.current.count, key: ad.type });
        increment({ object: adsDelay.current.totalDelay, key: ad.type, count: adDelay });

        const onPopSuccess = () => {
          queues.current?.poppedAdsQueue.push(ad);
        };

        const onPopFinally = () => {
          onUpdateQueue(ad, 'providerQueue');
        };

        await triggerPopFlow({ ad, onPopSuccess, onPopFinally });
      }
    }
  };

  const getNext = () => {
    if (!queues.current) {
      return;
    }

    cleanupExpiredAds(queues.current?.providerQueue as IAdLoop[], adExpirationTime.current);
    popAds();

    const {
      poppedAdsQueue, directQueue, customQueue, fallbackQueue,
    } = queues.current;

    // @ts-ignore
    const adArray: Actions[] = [];
    const pushToAdArray = (count: number, action: Actions) => {
      for (let i = 0; i < count; i += 1) {
        adArray.push(action);
      }
    };

    if (poppedAdsQueue?.length > 0) {
      pushToAdArray(state.config.weights?.provider, { type: 'SET_AD', payload: poppedAdsQueue[0] });
    }

    // if (providerQueue.length > 0) {
    //   const adIndex = getProviderAdIndexByPriority(providerQueue, providerPriorities.current);
    //   if (adIndex >= 0) {
    //     pushToAdArray(state.config.weights?.provider, { type: 'SET_AD', payload: providerQueue[adIndex] });
    //   }
    // }

    if (directQueue.length > 0) {
      const randomIndex = Math.floor(Math.random() * directQueue.length);
      pushToAdArray(state.config.weights?.direct, { type: 'SET_AD', payload: directQueue[randomIndex] });
    }

    if (customQueue.length > 0) {
      const randomIndex = Math.floor(Math.random() * customQueue.length);
      pushToAdArray(state.config.weights?.custom, { type: 'SET_AD', payload: customQueue[randomIndex] });
    }

    // news and weather logic
    const componentArray = [];

    if (!componentRef.current?.news.error) {
      componentArray.push({ payload: StrapiComponentType.News, weight: componentRef.current.news.weight });
    }

    if (!componentRef.current?.weather.error) {
      componentArray.push({ payload: StrapiComponentType.Weather, weight: componentRef.current.weather.weight });
    }

    const weightedComponentArray = getWeightedAdArray(componentArray);

    const dispatchActionsArray = weightedComponentArray.map((component) => ({
      type: 'SET_COMPONENT',
      payload: component.payload,
    }));

    if (dispatchActionsArray.length) {
      const randomIndex = Math.floor(Math.random() * dispatchActionsArray.length);

      // @ts-ignore
      pushToAdArray(state.config.weights?.component, dispatchActionsArray[randomIndex]);
    }

    // fallback logic
    if (fallbackQueue.length > 0) {
      const randomIndex = Math.floor(Math.random() * fallbackQueue.length);
      pushToAdArray(state.config.weights?.fallback, { type: 'SET_AD', payload: fallbackQueue[randomIndex] });
    }

    const weightedDispatchIndex = Math.floor(Math.random() * adArray.length);

    if (adArray.length === 0) {
      // try again in 5s if no ads are found
      setTimeout(() => {
        getNext();
      }, 5000);
      return;
    }

    dispatch(adArray[weightedDispatchIndex]);
  };

  const getProviderAd = async (provider: AdType) => {
    const totalAdQueueCount = queues.current?.providerQueue.length || 0;
    const providerAdQueueCount = queues.current?.providerQueue.filter((ad) => ad.type === provider).length || 0;

    if (providerAdQueueCount > queueCap.current[provider]) return;

    const count = totalAdQueueCount < AD_REQUEST_BATCH_SIZE ? totalAdQueueCount || 1 : AD_REQUEST_BATCH_SIZE;

    try {
      const response = await getProviderAds({ adType: provider, count, country });
      increment({ object: log.current.adsRequested, key: provider, count });
      latestAdRequest.current = Date.now();

      if (response.length === 0 || !queues.current) return;

      if (provider === AdType.Vistar) {
        response.forEach((ad) => {
          setTimeout(() => {
            triggerPopFlow({ ad });
          }, (ad.duration || 6) * 1000);
        });
        return;
      }

      queues.current?.providerQueue.push(...response);
      counts.current[provider] = getAdLoopByType(queues.current.providerQueue, provider).length;

      const { cooldown, threshold } = queueNotificationParameters.current;

      // eslint-disable-next-line no-mixed-operators
      if (counts.current[provider] >= (queueCap.current[provider] * threshold / 100)) {
        sendQueueCapReachedNotification(counts.current, cooldown);
      }

      increment({ object: log.current.adsQueued, key: provider, count: response.length });
    } catch (error) {
      console.error(`Failed to fetch ads from provider ${provider}:`, error);
    }
  };

  const mounted = useRef(false);
  const startProviderLoop = async (provider: AdType) => {
    while (mounted.current) {
      await getProviderAd(provider);
      await delay(adRequestIntervals.current[provider] as number);
    }
  };

  const fetchInitQueues = async () => {
    dispatch({ type: 'SET_LOADING' });

    const config = await getQueueConfig();
    const adsResponse = await getInitQueues({ country });

    if ('error' in adsResponse) {
      dispatch({ type: 'SET_ERROR' });
      handleQueues(null);
      return;
    }

    const { ads, adProviders } = adsResponse;

    if (!('error' in config)) {
      config.weights = getSimplifiedQueueWeights(config.weights);
      if (config.queueCap) queueCap.current = config.queueCap;
      if (config.queueNotificationParameters) queueNotificationParameters.current = config.queueNotificationParameters;
      if (config.providerPriorities) providerPriorities.current = config.providerPriorities;
      if (config.adRequestIntervals) adRequestIntervals.current = config.adRequestIntervals;
      if (config.adExpirationTime) adExpirationTime.current = config.adExpirationTime;

      dispatch({ type: 'SET_QUEUE_CONFIG', payload: config });
    }

    const initialCounts = {} as Record<string, number>;
    adProviders.forEach((type) => { initialCounts[type] = 0; });
    counts.current = initialCounts;
    dispatch({ type: 'TOGGLE_LOADING', payload: false });
    adProviders.forEach((provider) => {
      startProviderLoop(provider);
    });

    handleQueues(ads);
    getNext();
  };

  const onUpdateQueue = (ad: IAdLoop, queueKey: keyof AdsQueue) => {
    if (queues.current && isAdProvider(ad?.type)) {
      const newQueue = cleanCurrentAd(ad, queues.current?.[queueKey] as IAdLoop[]);
      (queues.current[queueKey] as IAdLoop[]) = newQueue;
      counts.current[ad.type] = getAdLoopByType(newQueue, ad.type).length;
    }
  };

  const onAdEnded = () => {
    if (queues.current) {
      // logging direct ads
      if (state.ad?.type === AdType.Direct) {
        const { uniqueId } = state.ad;

        const currDirectLog = directLog.current.find((item) => item.cmsAdId === uniqueId);
        if (currDirectLog) {
          currDirectLog.playCount += 1;
        } else {
          directLog.current.push({
            cmsAdId: uniqueId,
            playCount: 1,
          });
        }
      }
    }

    onUpdateQueue(state.ad, 'poppedAdsQueue');
    getNext();
  };

  const onAdError = () => {
    if (state.error || state.loading || !queues.current) {
      return;
    }

    onUpdateQueue(state.ad, 'poppedAdsQueue');
    getNext();
  };

  useEffect(() => {
    mounted.current = true;
    fetchInitQueues();
    const lastRefresh = new Date().toString();
    localStorage.setItem('lastRefresh', lastRefresh);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.error) {
      return;
    }

    console.log('Resetting ad loop...');

    const timerId = setTimeout(() => {
      fetchInitQueues();
    }, 20 * 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error]);

  useInterval(() => {
    socket.emit('keepAlive', device?.id);
    log.current.latestAdRequest = latestAdRequest.current;
    log.current.queues = counts.current;

    const computeAverageDelayPerProvider = () => {
      const { totalDelay, count } = adsDelay.current;
      Object.keys(totalDelay).forEach((key) => {
        // @ts-ignore
        log.current.adsDelay[key] = Math.floor(totalDelay[key] / count[key]);
      });
    };

    computeAverageDelayPerProvider();
    registerLog(log.current);

    log.current.pops = {};
    log.current.adsQueued = {};
    log.current.failedPops = {};
    log.current.adsRequested = {};
    log.current.adsDelay = {};

    adsDelay.current = { totalDelay: {}, count: {} };

    if (directLog.current.length > 0) {
      registerDirectAdLog(directLog.current);
      directLog.current = [];
    }
  }, 5 * 60 * 1000);

  if (state.loading) {
    return (
      <AdContainer>
        <LoadingSpinner />
      </AdContainer>
    );
  }

  if (state.error) {
    return (
      <AdContainer>
        <BrandedImage
          src='https://portl-assets.s3.amazonaws.com/advertise-fallback.webp'
          alt='Error'
        />
        <Logo src='././logo.webp' />
      </AdContainer>
    );
  }

  return (
    <AdContainer>
      {!isProduction && <AdCounter popsCount={pops.current} stateCounts={counts.current} />}
      <PortlTransition>
        <AdPlayer
          mediaFile={state.ad?.mediaFile}
          duration={state.ad?.duration}
          onEnded={onAdEnded}
          onError={onAdError}
          componentProps={{ componentType: state.component, component: componentRef.current[state.component] }}
        />
      </PortlTransition>
    </AdContainer>
  );
};

export default AdLoop;
