import axios from 'axios';
import {
  AdLocation, AdProviders, AdType, DeviceLocation,
} from '../types';
// eslint-disable-next-line import/no-cycle
import { IAdLoop } from '../views/AdLoop/requests';
import { streetsUrl } from '../config';

export const getCoordinates = (): Promise<GeolocationPosition> => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject, {
    enableHighAccuracy: true,
    timeout: 2000,
  });
});

export const getLocation = async (): Promise<DeviceLocation> => {
  try {
    const position = await getCoordinates();
    const { coords } = position;
    const { latitude, longitude } = coords;
    return { lat: latitude, lng: longitude };
  } catch (error) {
    return { error };
  }
};

export const isWithinCircle = async ({
  lat: latCircle,
  lng: lngCircle,
  radius,
}: AdLocation): Promise<boolean | null> => {
  if (!latCircle || !lngCircle || !radius) return null;

  const { lat, lng, error } = await getLocation();
  if (error || !lat || !lng) return null;

  // find the distance between current location and center of circle
  const R = 6371e3; // radius of earth in metres
  const φ1 = (lat * Math.PI) / 180; // convert φ, λ to radians
  const φ2 = (latCircle * Math.PI) / 180;
  const Δφ = ((latCircle - lat) * Math.PI) / 180;
  const Δλ = ((lngCircle - lng) * Math.PI) / 180;

  const x = Δλ * Math.cos((φ1 + φ2) / 2);
  const y = Δφ;
  const distance = Math.sqrt(x * x + y * y) * R;

  // return true/false
  return distance <= radius;
};

export const getAdLoopByType = (adLoop: IAdLoop[], adType: AdType): IAdLoop[] => adLoop.filter((ad) => ad.type === adType);
export const isAdProvider = (type: AdType | undefined): boolean => AdProviders.includes(type as AdType);

export const cleanCurrentAd = (ad: IAdLoop, queue: IAdLoop[]): IAdLoop[] => {
  if (ad.mediaFile?.blobURL) {
    URL.revokeObjectURL(ad.mediaFile.blobURL);
  }
  return queue.filter((currAd) => currAd.uniqueId !== ad.uniqueId);
};

type IncrementProps = {
  object: Record<string, number>
  key: string;
  count?: number;
};

export const increment = ({ object, key, count = 1 }: IncrementProps): void => {
  if (!object[key]) {
    object[key] = 0;
  }
  object[key] += count;
};

type GroupedObject<T> = {
  [key: string]: T[];
};

export const groupBy = <T>(arr: T[], key: string): GroupedObject<T> => arr.reduce((acc, currentItem) => {
  const itemKey: string = (currentItem as any)[key];

  if (!acc[itemKey]) {
    acc[itemKey] = [];
  }

  acc[itemKey].push(currentItem);

  return acc;
}, {} as GroupedObject<T>);

export const downloadMedia = async (mediaUrl: string): Promise<string> => {
  if (mediaUrl) {
    try {
      const { data } = await axios.get(`${streetsUrl}/media`, {
        params: { url: mediaUrl },
        responseType: 'blob',
      });
      return URL.createObjectURL(data);
    } catch (err) {
      console.error(err);
    }
  }

  return '';
};

export const getNth = (n: number): string => (n > 3 && n < 21 ? 'th' : n % 10 === 1 ? 'st' : n % 10 === 2 ? 'nd' : n % 10 === 3 ? 'rd' : 'th');

export function getSimplifiedQueueWeights<T>(weights: T): T {
  const findGCD = () => {
    const arr = Object.values(weights);
    const gcd = (a: number, b: number): number => {
      if (a === 0) return b;
      return gcd(b % a, a);
    };

    let result = arr[0];
    const n = arr.length;
    for (let i = 1; i < n; i += 1) {
      result = gcd(arr[i], result);

      if (result === 1) {
        return 1;
      }
    }
    return result;
  };

  const gcd = findGCD();

  const simplifiedWeights = {} as T;
  Object.keys(weights).forEach((key) => {
    // @ts-ignore
    simplifiedWeights[key] = weights[key] / gcd;
  });

  return simplifiedWeights;
}

export function getWeightedAdArray<T extends { weight: number }>(arr: T[]): T[] {
  let weights: Record<string, number> = {};
  // eslint-disable-next-line consistent-return,no-restricted-syntax
  for (const ad of arr) {
    // @ts-ignore
    weights[String(arr.indexOf(ad))] = ad.weight;
  }
  weights = getSimplifiedQueueWeights(weights);
  const weightedAds: T[] = [];
  Object.keys(weights).forEach((key) => {
    // @ts-ignore
    for (let i = 0; i < weights[key]; i += 1) {
      weightedAds.push(arr[Number(key)]);
    }
  });
  return weightedAds;
}

export const getProviderAdIndexByPriority = (adQueue: IAdLoop[], providerPriorities: AdType[]): number => {
  // eslint-disable-next-line consistent-return,no-restricted-syntax
  for (const provider of providerPriorities) {
    const adToPlayIndex = adQueue.findIndex((ad: IAdLoop) => ad.type === provider);
    if (adToPlayIndex >= 0) {
      return adToPlayIndex;
    }
  }
  return -1;
};

export const cleanupExpiredAds = (adQueue: IAdLoop[], adExpirationTimes: Record<string, number>): void => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < adQueue.length; i++) {
    const now = new Date().getTime();
    const { createdAt, type } = adQueue[i];
    const delay = now - createdAt;
    const expirationTime = adExpirationTimes[type] * 60 * 1000;

    // @ts-ignore
    if (delay > expirationTime) {
      adQueue.splice(i, 1);
      i -= 1;
    } else {
      return;
    }
  }
};

export const LOCATION_LOGIN_NOTIFICATION_WAIT_TIME = 60 * 1000;
export const NotificationType = {
  Location: 'location',
  Login: 'login',
  QueueCap: 'queueCap',
};

export const isObjectEmpty = (obj: Record<any, unknown>): boolean => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

// type ReplaceMediaUrlProps = {
//   adsQueue: IAdLoop[],
//   index?: number
// };

// export const replaceMediaUrl = async ({ adsQueue, index = 0 }: ReplaceMediaUrlProps): Promise<void> => {
//   const mediaUrl = adsQueue[index].mediaFile.blobURL;
//   const media = await downloadMedia(mediaUrl);
//   if (media) {
//     adsQueue[index].mediaFile.blobURL = media;
//   }
// };
